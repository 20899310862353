import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSaleList() {
  // Use toast
  const toast = useToast()

  const refPurchaseListTable = ref(null)

  const perPage = ref(5)
  const totalSales = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const branchFilter = ref(null)
  const supplierFilter = ref(null)
  const statusFilter = ref(null)
  const dateFilterValue = ref(null)

  const current = new Date()
  const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
  const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
  const today = `${current.getFullYear()}-${month}-${date}`
  dateFilterValue.value = today

  const totalAmount = ref(0)
  const totalDiscount = ref(0)
  const netTotal = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refPurchaseListTable.value ? refPurchaseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSales.value,
    }
  })

  const refetchData = () => {
    refPurchaseListTable.value.refresh()
  }

  const resolveStatusVariant = status => {
    if (status === 0) return 'info'
    if (status === 1) return 'danger'
    if (status === 2) return 'warning'
    if (status === 3) return 'success'
    if (status === 4) return 'secondary'
    return 'primary'
  }
  watch([currentPage, perPage, branchFilter, supplierFilter, statusFilter, dateFilterValue], () => {
    refetchData()
  })

  const fetchPurchases = (ctx, callback) => {
    store
      .dispatch('purchase/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        branch_id: branchFilter.value,
        supplier_id: supplierFilter.value,
        status: statusFilter.value,
        date_range: dateFilterValue.value,
      })
      .then(response => {
        const { purchases, total } = response.data.data

        callback(purchases)
        totalSales.value = total
        totalAmount.value = response.data.data.total_amount
        totalDiscount.value = response.data.data.total_discount
        netTotal.value = response.data.data.net_total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting purchase list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchPurchases,
    perPage,
    currentPage,
    totalSales,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPurchaseListTable,

    refetchData,
    totalAmount,
    totalDiscount,
    netTotal,

    resolveStatusVariant,

    // Filters
    branchFilter,
    supplierFilter,
    statusFilter,
    dateFilterValue,
  }
}
